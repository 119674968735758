<template>
  <main class="store-setting">
    <PageTitle
      title="門市設定"
      icon="chevron_left"
      style="padding-bottom: 24px"
      btn="新增"
      btn2="匯入"
      @iconClick="$router.push({ name: 'Parameters' })"
      @btnClick="openDialog('create')"
      @btn2Click="modal.import = true"
    />
    <FilterContainer>
      <BaseElInput
        v-model="search.name"
        placeholder="搜尋門市名稱"
        clearable
        @clear="refresh(true)"
        @keypress.enter.native="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </BaseElInput>
    </FilterContainer>
    <BaseTable v-loading="loading" :data="tableData" empty-text="暫無數據">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn prop="name" label="門市名稱" align="center" />
      <BaseElTableColumn prop="phone" label="門市電話" align="center" />
      <BaseElTableColumn prop="address" label="門市地址" align="center" />
      <BaseElTableColumn prop="order" label="排序" align="center" />
      <BaseElTableColumn label="操作" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            hideDelete
            @edit="
              openDialog('update'), (selectRow = scope.row), syncFormData(scope.row)
            "
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="getStore()"
    />

    <!-- Dialog -->
    <el-dialog
      v-if="showDialog"
      :visible="showDialog"
      :close-on-click-modal="false"
      :title="dialogType === 'create' ? '新增門市資訊' : '編輯門市資訊'"
      @close="(showDialog = false), resetForm()"
    >
      <BaseElForm
        ref="formRef"
        :model="storeForm.data"
        :rules="formRules"
        label-position="top"
      >
        <BaseElFormItem label="門市名稱" prop="name">
          <BaseElInput v-model="storeForm.data.name" placeholder="請輸入門市名稱" />
        </BaseElFormItem>

        <BaseElFormItem label="門市電話" prop="phone">
          <BaseElInput v-model="storeForm.data.phone" placeholder="請輸入門市電話" />
        </BaseElFormItem>

        <BaseElFormItem label="客服聯繫方式" prop="contact.type">
          <BaseElSelect v-model="storeForm.data.contact.type" placeholder="請選擇客服聯繫方式" prop="contact.type">
            <BaseElSelectOption
              v-for="item in contactType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </BaseElSelect>
        </BaseElFormItem>

        <BaseElFormItem v-if="storeForm.data.contact.type === 'phone'" label="電話號碼" prop="contact.phone">
          <BaseElInput v-model="storeForm.data.contact.phone" placeholder="請輸入電話號碼" />
        </BaseElFormItem>

        <BaseElFormItem v-if="storeForm.data.contact.type === 'line'" label="LINE ID" prop="contact.lineId">
          <BaseElInput v-model="storeForm.data.contact.lineId" placeholder="請輸入LINE ID" />
        </BaseElFormItem>

        <BaseElFormItem label="門市地址" prop="address">
          <BaseElInput v-model="storeForm.data.address" placeholder="請輸入門市地址" />
        </BaseElFormItem>

        <BaseElFormItem label="排序" prop="order">
          <BaseElInput v-model="storeForm.data.order" placeholder="請輸入排序" />
        </BaseElFormItem>
      </BaseElForm>

      <div slot="footer">
        <BaseElButton plain @click="(showDialog = false), resetForm()">
          取消
        </BaseElButton>
        <BaseElButton
          v-loading="btnLoading"
          type="primary"
          @click="handleDialogConfirm"
        >
          確認
        </BaseElButton>
      </div>
    </el-dialog>

    <FileImportDialog
      v-if="modal.import"
      :examplaeData="examplaeImportData"
      :handleSubmit="handleImport"
      @close="modal.import = false"
    >
      <template slot="notice">
        注意：若檔案內容有錯誤時，系統將取消本次操作不進行任何匯入。<br>
        請下載「失敗說明檔案」進行調整後再次匯入。
      </template>
    </FileImportDialog>

    <BaseDialog v-if="modal.importSuccess" title="匯入成功" @close="modal.importSuccess = false" @confirm="modal.importSuccess = false">
      <div style="margin-bottom: 30px">
        檔案匯入成功，請查看結果
      </div>
    </BaseDialog>
    <BaseDialog v-if="modal.importError" title="匯入失敗" @close="modal.importError = false" @confirm="modal.importError = false">
      <div style="margin-bottom: 30px">
        <p style="margin-bottom: 30px">檔案匯入失敗，請下載檔案進行調整後再次匯入</p>
        <p class="text-primary-100 cursor-pointer underline" @click="downloadErrorFile">失敗說明檔案</p>
      </div>
    </BaseDialog>
  </main>
</template>

<script>
// page - 門市設定
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import { isDigitRules, noEmptyRules, phoneRules } from '@/validation'
import { map, filter, get, isEmpty, toUpper } from 'lodash'
import store from '@/store'
import formUtils from '@/utils/form'
import { useTable } from '@/use/table'
import FilterContainer from '@/components/Container/FiltersContainer.vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import FileImportDialog from '@/components/Dialog/FileImportDialog.vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { GetBranchStore, GetBranchStoreCount, CreateBranchStore, UpdateBranchStore, BatchCreateBranchStore } from '@/api/branchStore'
import { ExportExcel } from '@/utils/excel'

export default defineComponent({
  name: 'StoreSettings',
  components: {
    PageTitle,
    EmptyBlock,
    FilterContainer,
    FileImportDialog,
    BaseDialog,
  },
  setup () {
    const { pageStartIndex, tableOptions, tableData, tableDataCount } = useTable()
    const showDialog = ref(false)
    const dialogType = ref(null)
    const btnLoading = ref(false)
    const formRef = ref(null)
    const loading = ref(false)
    const errorFileData = ref([])
    const storeForm = reactive({
      data: {
        name: '',
        phone: '',
        address: '',
        order: '',
        contact: {
          type: '',
          phone: '',
          lineId: '',
        },
      },
    })
    const shopId = computed(() => store.getters.shop)
    const search = reactive({
      name: null,
    })
    const contactType = {
      phone: { label: '電話號碼', value: 'phone' },
      line: { label: 'LINE', value: 'line' },
    }
    const modal = reactive({
      import: false,
      importSuccess: false,
      importError: false,
    })
    const formRules = computed(() => {
      const rules = {
        name: [noEmptyRules()],
        phone: [noEmptyRules(), phoneRules(true, true, true)],
        address: [noEmptyRules()],
        order: [noEmptyRules(), isDigitRules()],
        contact: [],
      }
      if (get(storeForm.data, 'contact.type') === 'phone') {
        rules.contact.phone = [noEmptyRules(), phoneRules(true, true, true)]
      }
      if (get(storeForm.data, 'contact.type') === 'line') {
        rules.contact.lineId = [noEmptyRules()]
      }

      return rules
    })

    const examplaeImportData = [
      { 門市名稱: 'Store A', 門市電話: '0200000000', 門市地址: '台北市中山區000號', 客服聯繫方式: 'LINE', 'Line ID': '@line', 電話號碼: '', 排序: 1 },
      { 門市名稱: 'Store B', 門市電話: '0300000000', 門市地址: '桃園市龜山區000號', 客服聯繫方式: 'LINE', 'Line ID': '@line123', 電話號碼: '', 排序: 2 },
      { 門市名稱: 'Store C', 門市電話: '0400000000', 門市地址: '台中市南屯區000號', 客服聯繫方式: '電話號碼', 'Line ID': '', 電話號碼: '0900000000', 排序: 3 },
    ]

    const getStore = async () => {
      const [res, err] = await GetBranchStore({
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        name: search.name || undefined,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      tableData.value = res
    }

    const findStoreCount = async () => {
      const [res, err] = await GetBranchStoreCount({
        shopId: shopId.value,
        name: search.name || undefined,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      tableDataCount.value = res.count
    }

    const createStore = async () => {
      const { name, phone, address, order, contact } = storeForm.data
      const [, err] = await CreateBranchStore({
        shopId: shopId.value,
        name,
        phone,
        address,
        order,
        contact: isEmpty(get(contact, 'type')) ? null : {
          ...contact,
          lineId: contact.lineId || undefined,
          phone: contact.phone || undefined,
        },
      })
      btnLoading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('新增成功!')
    }

    const updateStore = async () => {
      const { name, phone, address, id, order, contact } = storeForm.data
      const [, err] = await UpdateBranchStore({
        shopId: shopId.value,
        storeId: id,
        name,
        phone,
        address,
        order,
        contact: isEmpty(contact) ? null : contact,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('新增成功!')
    }

    const handleImport = async (data) => {
      const importData = filter(data, i => i.length)
      const branchs = []
      errorFileData.value = []

      importData.forEach((item, index) => {
        const type = toUpper(item[3]) === 'LINE' ? 'line' : item[3] === '電話號碼' ? 'phone' : undefined

        if (type === undefined) {
          errorFileData.value.push({
            門市名稱: item[0],
            門市電話: item[1],
            門市地址: item[2],
            匯入成功: '否',
            錯誤訊息: `客服聯繫方式格式錯誤：${item[3]}`,
          })
        } else {
          branchs.push({
            name: item[0],
            phone: item[1],
            address: item[2],
            order: item[6],
            contact: {
              type: type,
              lineId: item[4] || undefined,
              phone: item[5] || undefined,
            },
          })
        }
      })

      if (errorFileData.value.length > 0) {
        // Handle errors
        modal.importError = true
        return
      }

      const [res, err] = await BatchCreateBranchStore({
        shopId: shopId.value,
        data: branchs,
      })
      if (err) throw err
      if (res.success) {
        modal.importSuccess = true
        refresh()
      }
      if (!res.success) {
        modal.importError = true
        const logs = res.logs
        errorFileData.value = []
        branchs.forEach((branchStore, index) => {
          errorFileData.value.push({
            門市名稱: branchStore.name,
            門市電話: branchStore.phone,
            門市地址: branchStore.address,
            匯入成功: get(logs, `${index}.success`) ? '是' : '否',
            錯誤訊息: get(logs, `${index}.errorMessages`),
          })
        })
      }
    }

    const downloadErrorFile = async () => {
      ExportExcel(errorFileData.value, '匯入失敗說明檔', '匯入失敗說明檔')
    }

    const openDialog = (type) => {
      dialogType.value = type
      showDialog.value = true
    }

    const syncFormData = (row) => {
      storeForm.data = JSON.parse(JSON.stringify(row))
      if (!get(row, 'contact')) {
        storeForm.data.contact = {}
      }
    }

    const resetForm = () => {
      Object.keys(storeForm.data).forEach((key) => {
        if (key === 'contact') {
          storeForm.data[key] = {}
          return
        }
        storeForm.data[key] = ''
      })
    }

    const checkForm = async () => {
      return await formUtils.checkForm(formRef.value)
    }
    const handleDialogConfirm = async () => {
      if (!(await checkForm())) return
      const type = dialogType.value
      try {
        if (type === 'create') await createStore()
        if (type === 'update') await updateStore()
        showDialog.value = false
        await getStore()
        resetForm()
      } catch (error) {
        window.$message.error(error)
      }
    }

    const refresh = async (search = false) => {
      if (loading.value) return
      if (search) tableOptions.page = 1
      loading.value = true
      await Promise.allSettled([getStore(), findStoreCount()])
      loading.value = false
    }

    onMounted(async () => {
      Promise.all([getStore(), findStoreCount()])
    })

    return {
      search,
      openDialog,
      resetForm,
      showDialog,
      dialogType,
      btnLoading,
      storeForm,
      formRules,
      handleDialogConfirm,
      syncFormData,
      formRef,
      getStore,
      tableOptions,
      tableData,
      tableDataCount,
      refresh,
      loading,
      examplaeImportData,
      handleImport,
      modal,
      downloadErrorFile,
      contactType,
      errorFileData,
    }
  },
})
</script>
